//fonts

$PRIMARY-FONT-FAMILY: 'SF Pro Display', sans-serif;
$SECONDARY-FONT-FAMILY: 'San Francisco Text Regular';
$BEBAS-FONT-FAMILY: 'Bebas Neue', cursive;

//colors
$PRIMARY-WHITE-COLOR: #FFFFFF;
$PRIMARY-DARK-GRAY-COLOR: #3B4857;
$PRIMARY-LIGHT-GRAY-COLOR: #8492A5;
$PRIMARY-LIGHTER-GRAY-COLOR: #D8D8D8;
$PRIMARY-GREEN-COLOR: #4359f5;
$PRIMARY-LIGHT-GREEN-COLOR: rgba(0,159,85,0.2);
$PRIMARY-ERROR-COLOR: #FF6C5C;
$PRIMARY-RED-COLOR: #FF0000;
$PRIMARY-BLUE-COLOR: #2B65B3;
$PRIMARY-ORANGE-COLOR: #DE8F00;
$PRIMARY-PURPLE-COLOR: #8200DA;
$TEXT-LIGHT-GRAY-COLOR: #8492A5;
$INPUT-BORDER-COLOR: rgba(0,0,0,0.38);
$INPUT-PLACEHOLDER-COLOR: #8492A5;
$NAV-BAR-BACKGROUND-COLOR: #FAFAFA;
$CHECKBOX-BACKGROUND-COLOR: #EFEFEF;

// font-sizes
$FONT-SIZE-BIG-TITLE: 36px;
$FONT-SIZE-LG-TITLE: 21px;
$FONT-SIZE-SM-TITLE: 16px;
$FONT-SIZE-LG-TEXT: 14px;
$FONT-SIZE-MD-TEXT: 12px;
$FONT-SIZE-SM-TEXT: 10px;

// path for images


