@import '../../assets/scss/variables';
@import "../../assets/fonts/fonts";


.input {
  width: 100%;
  border-radius: 5px;
  padding: 0 16px;
  .MuiOutlinedInput-root{
    height: 56px;
  }
  .MuiFormControl-root{
    margin: 0;
  }
  .MuiOutlinedInput-notchedOutline{
    border-color: $INPUT-BORDER-COLOR;
    height: 56px;
  }
  .MuiInputBase-input {
    border-radius: 5px;
    color: $PRIMARY-DARK-GRAY-COLOR;
    padding: 0;
    height: 56px;
  }
  .MuiFormLabel-root{
    color: $PRIMARY-LIGHT-GRAY-COLOR;
  }
}
.input-wrapper{
  position: relative;
  .show-password{
    position: absolute;
    right: 0;
    bottom: 12px;
  }
}



