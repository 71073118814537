@import "../../assets/scss/variables";
@import "../../assets/fonts/fonts";

.favorites-wrapper{
  display: flex;
  flex: 1;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 20px;
  .list-wrapper{
    padding: 0;
  }
}
.favorites-content{
  display: flex;
  flex: 1;
  height: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 52px 0;
}
.content{
  width: 100%;
  max-width: 991px;
  padding: 0 12px;
  margin: 0 auto;
}
.content-child{
  padding: 76px 12px;
  width: 100%;
}
.list {
  .list-header{
    width: 100%;
    height: 58px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    color: #515151;
    font-weight: 600;
    border-top: 1px solid #D8D8D8;
    border-bottom: 1px solid #D8D8D8;
    margin-bottom: -1px;
    cursor: pointer;
    .link{
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
    .info-col{
      display: flex;
      align-items: center;
    }
  }
}
.selected-classifier{
  padding: 8px 16px;
  .item{
    color: #3B4857;
    border-radius: 5px;
    margin-right: 5px;
    font-size: $FONT-SIZE-MD-TEXT;
    font-weight: 600;
    display: inline-block;
    &.show-more{
      padding: 4px 12px;
      color: $PRIMARY-WHITE-COLOR;
    }
    &:last-child{
      &:after{
        position: relative;
        content: '';
      }
    }
    &:after{
      position: relative;
      content: ',';
    }
  }
}
