.loading-spinner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255,255,255,0.9);
  z-index: 99999999;
  img{
    width: 40px;
    height: 40px;
  }
}
.preloader-wrapper{
  position: fixed;
  bottom: 80px;
  width: 100%;
  height: 50px;
  z-index: 99;
  left: 0;
}
