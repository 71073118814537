@import '../../assets/scss/variables';
@import "../../assets/fonts/fonts";

.search-wrapper{
  width: 100%;
  height: 35px;
  position: relative;
  margin: 0 0 10px 0;
  .search{
    width: 100%;
    height: 100%;
    border-radius: 5px;
    border: 1px solid $INPUT-BORDER-COLOR;
    padding: 0 16px 0 36px;
    color: $PRIMARY-DARK-GRAY-COLOR;
    &:focus{
      border-color: $PRIMARY-GREEN-COLOR;
    }
    &::placeholder{
      color: $INPUT-PLACEHOLDER-COLOR;
    }
    &::-moz-placeholder{
      color: $INPUT-PLACEHOLDER-COLOR;
    }
    &:-ms-input-placeholder{
      color: $INPUT-PLACEHOLDER-COLOR;
    }
  }
  &:before{
    content: '';
    position: absolute;
    width: 16px;
    height: 16px;
    top: 10px;
    left: 10px;
    background: url("../../assets/images/search.svg") no-repeat center;
    background-size: contain;
  }
}
