@import '../../assets/scss/variables';
@import "../../assets/fonts/fonts";

.breadcrumbs{
  position: relative;
  max-width: 991px;
  width: 100%;
  margin: 0 auto;
  text-align: left;
  padding: 0 12px;
  top: 65px;
  z-index: 55;
  padding-left: 40px;
  .item{
    color: $PRIMARY-GREEN-COLOR;
    font-size: $FONT-SIZE-MD-TEXT;
    line-height: 29px;
    //margin-bottom: 10px;
    .icon{
      display: none;
      color: $PRIMARY-GREEN-COLOR;
      font-size: 29px;
    }
    &:first-child{
      color: transparent;
      width: 29px;
      height: 29px;
      overflow: hidden;
      position: absolute;
      left: 8px;
      .icon{
        display: block;
      }
    }
    &:nth-child(2){
      position: relative;
      margin-left: 5px;
      &:before{
        content: '-';
        position: relative;
        left: -4px;
      }
    }
    &.active{
      color: $PRIMARY-DARK-GRAY-COLOR;
      font-weight: 600;

    }
  }

}
