@import "../../assets/fonts/fonts";

.requests-summary-status-line-wrapper,
.requests-summary-status-line{
  position: relative;
  width: 100%;
  height: 20px;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 16px;
  div {
    border-radius: 20px;
    position: relative;
    &:after{
      content: '';
      position: absolute;
      width: 25px;
      height: 20px;
      background: inherit;
      right: -17px;
      z-index: 2;
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
    }
  }
  .line-overlay{
    position: absolute;
    width: 100%;
    background: #515151;
    left: 0;
    right: 0;
    height: 100%;
  }
}
