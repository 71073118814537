@import "../../assets/scss/variables";
@import "../../assets/fonts/fonts";
.login-wrapper{
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  height: 100%;
  .logo{
    width: 103px;
    height: 104px;
    margin: 0 auto 23px auto;
    background: url("../../assets/images/logo.svg") no-repeat center;
  }
  .login-container{
    max-width: 375px;
    padding: 0 10px;
    margin: 0 auto;
  }

  .btn-container{
    margin-top: 41px;
  }
  h1{
    color: $PRIMARY-DARK-GRAY-COLOR;
    text-transform: uppercase;
  }
  .input-container{
    margin-top: 41px;
  }
  .register-block{
    text-align: center;
    display: flex;
    flex-direction: column;
    margin-top: 25px;
    color: $PRIMARY-LIGHT-GRAY-COLOR;
    span{
      margin-bottom: 10px;
    }
  }
}
