@import "assets/scss/variables";
.App {
  height: 100%;
  font-family: $PRIMARY-FONT-FAMILY;
}
h1{
  font-size: $FONT-SIZE-BIG-TITLE;
  font-weight: 600;
}
h2{
  font-size: $FONT-SIZE-LG-TITLE;
  font-weight: 600;
}
h3{
  font-size: $FONT-SIZE-SM-TITLE;
  font-weight: 600;
}
h4{
  font-size: $FONT-SIZE-LG-TEXT;
  font-weight: 600;
}
