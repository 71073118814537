.Dashboard {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  .content {
    position: relative;
    &.fixed {
      overflow: hidden;
    }
  }
  .content-inner{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    flex: 1;
    height: 100%;
  }
}
