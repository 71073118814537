@import '../../assets/scss/variables';
@import "../../assets/fonts/fonts";

.list-wrapper{
  padding: 0 12px;
}
.product-list-wrapper{
  background: #F8F8F8;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
  margin: 10px 0 20px 0;
  border-radius: 5px;
  text-align: left;
  position: relative;
  .list-header{
    display: flex;
    justify-content: space-between;
    padding: 12px 20px 0 20px;
  }
  h2{
    color: $PRIMARY-DARK-GRAY-COLOR;
    font-family: $SECONDARY-FONT-FAMILY;
    font-size: 16px;
    font-weight: bold;
    display: flex;
    &.card-header{
      padding: 20px 20px 0 20px;
    }

  }
  .product-statistic-list{
    h2{
      padding: 0 16px;
    }
  }
  .product-list {
    margin-top: 15px;
    .list-item{
      display: flex;
      justify-content: space-between;
      color: $PRIMARY-DARK-GRAY-COLOR;
      font-size: $FONT-SIZE-MD-TEXT;
      font-family: $SECONDARY-FONT-FAMILY;
      padding: 4px 20px;
      font-weight: 600;
      align-items: center;
      min-height: 45px;
      &:nth-child(odd){
        background: #EEEEEE;
      }
      .name{
        font-size: $FONT-SIZE-MD-TEXT;
        font-family: $SECONDARY-FONT-FAMILY;
        font-weight: 600;
        @media (max-width: 640px) {
          max-width: 130px;
        }
      }
      .prices{
        color: $PRIMARY-DARK-GRAY-COLOR;
        font-size: $FONT-SIZE-SM-TITLE;
        font-family: $SECONDARY-FONT-FAMILY;
        font-weight: 600;
        display: flex;
        align-items: center;
        @media (max-width: 480px) {
          font-size: $FONT-SIZE-MD-TEXT;
        }
        &.price-up{
          color: $PRIMARY-RED-COLOR;
        }
        &.price-down{
          color: #02a151;
        }
        .arrow-block{
          width: 25px;
          display: flex;
          align-items: center;
          height: 17px;
          justify-content: center;
        }
        .arrow-right{
          position: absolute;
          right: 0;
          color: $PRIMARY-DARK-GRAY-COLOR;
        }
      }
      .list-item-link{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
  .no-data{
    text-align: center;
    padding: 12px;
    color: $PRIMARY-DARK-GRAY-COLOR;
  }
  .nav-list{
    display: flex;
    li{
      margin-left: 10px;
      position: relative;
      .sort-icon{
        cursor: pointer;
        color: $PRIMARY-ORANGE-COLOR;
      }
    }
  }
  .sort-popup{
    position: absolute;
    right: 0;
    background: $PRIMARY-WHITE-COLOR;
    width: 200px;
    font-size: $FONT-SIZE-LG-TEXT;
    box-shadow: 0 0 20px #0000007a;
    border-radius: 10px;
    overflow: hidden;
    top: 30px;
    z-index: 22;
    h5{
      font-size: 16px;
      font-family: $SECONDARY-FONT-FAMILY;
      font-weight: 600;
      padding: 12px 24px;
      color: #202020;
    }
    ul{
      margin: 0;
      padding: 0;
      li{
        margin: 0;
        padding: 16px 24px;
        cursor: pointer;
        color: $PRIMARY-DARK-GRAY-COLOR;
        &:nth-child(odd){
          background: $CHECKBOX-BACKGROUND-COLOR;
        }
        &:hover{
          background: $PRIMARY-GREEN-COLOR;
          color: $PRIMARY-WHITE-COLOR;
        }
      }
    }
  }
}
