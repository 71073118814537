@import "../../assets/scss/variables";
@import "../../assets/fonts/fonts";

.service-page {
  max-width: 991px;
  width: 100%;
  min-width: 320px;
  padding: 74px 0 130px 0!important;

  .container {
    padding: 0 10px 50px 10px;
  }

  .line {
    width: 100%;
    height: 1px;
    background: rgba($INPUT-BORDER-COLOR, 0.3);
    margin-bottom: 10px;
  }

  .search-wrapper {
    margin: 0;
  }

  .MuiAppBar-colorPrimary {
    box-shadow: none;
    height: 33px;
    background: transparent;
    margin-bottom: 5px;
  }

  .MuiTabs-flexContainer {
    justify-content: space-between;
  }

  .MuiTab-textColorInherit {
    background: transparent;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    max-width: 100%;
    min-height: 33px;
    color: $PRIMARY-DARK-GRAY-COLOR;
    font-size: $FONT-SIZE-LG-TEXT;
    text-transform: capitalize;
    border-radius: 0;
    border-bottom: 1px solid #E3E3E3;
    &.Mui-selected {
      color: $PRIMARY-GREEN-COLOR;
      background: transparent;
      padding: 0 0 1px 0;
      border-bottom: 1px solid $PRIMARY-GREEN-COLOR;
    }
  }

  .MuiTabs-root {
    min-height: 44px;
    background: $PRIMARY-WHITE-COLOR;
    border-radius: 5px;

  }

  .MuiTabs-indicator {
    display: none !important;
  }

  .MuiBox-root {
    padding: 0;
  }
}
.current-filters-list{
  margin-bottom: 5px;
  padding: 0 10px;
  text-align: left;
  li{
    display: inline-block;
  }
  .item{
    color: $PRIMARY-GREEN-COLOR;
    border-radius: 5px;
    margin-right: 5px;
    font-size: $FONT-SIZE-MD-TEXT;
    font-weight: 600;
    display: inline-block;
    &:last-child{
      &:after{
        position: relative;
        content: '';
      }
    }
    &:after{
      position: relative;
      content: ',';
    }
  }
}
