@import '../../assets/scss/variables';
@import "../../assets/fonts/fonts";

.department-card{
  width: 100%;
  border-radius: 10px;
  min-height: 100px;
  overflow: hidden;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-family: $SECONDARY-FONT-FAMILY;
  -webkit-box-shadow: 0px 3px 7px #ACB5F5;
  box-shadow: 0px 3px 7px #ACB5F5;
  .overlay{
    background: rgba($PRIMARY-WHITE-COLOR, 0.5);
    cursor: no-drop;
  }
  &.service-department{
    background: url("../../assets/images/office-phone.jpg") center center;
    background-size: cover;
  }
  &.economic-department{
    background: url("../../assets/images/economics-service.png") center center;
    background-size: cover;
  }
  &-overlay {
    //background: rgba($PRIMARY-GREEN-COLOR, 0.5);
    padding: 20px;
    width: 100%;
    min-height: 120px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
  }
  h2{
    color: $PRIMARY-WHITE-COLOR;
    font-family: $PRIMARY-FONT-FAMILY;
    font-weight: 600;
    text-align: left;
  }
  .department-block-wrapper{
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
  }
  .department-block{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0 20px;
    &:first-child {
      margin-left: 0;
    }
  }
  .department-status{
    width: 50px;
    height: 50px;
    border-radius: 50px;
    margin: 0 auto;
  }
  .department-name{
    margin-top: 5px;
    color: $PRIMARY-WHITE-COLOR;
    font-size: $FONT-SIZE-SM-TEXT;
    font-family: $SECONDARY-FONT-FAMILY;
  }
  &.statistic{
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    h2{
      width: 100%;
      border-bottom: 2px solid $PRIMARY-WHITE-COLOR;
      padding-bottom: 16px;

    }
    .statistic-wrapper{
      border-radius: 10px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #FFFFFF;
      font-weight: 600;
      flex-direction: column;
      padding: 4px 12px;
      background: rgba(255,255,255,0.2);
      margin-top: 20px;
      min-height: 117px;
      h3{
        font-weight: 600;
        margin-bottom: 5px;
        font-size: $FONT-SIZE-SM-TITLE;
        font-family: $SECONDARY-FONT-FAMILY;
      }
      .spiner{
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .summary-events{
        border-bottom: 2px solid $PRIMARY-WHITE-COLOR;
      }
    }
    .statistic-list{
      width: 100%;
      display: flex;
      color: $PRIMARY-WHITE-COLOR;
      font-weight: 600;
      flex-direction: column;
      li{
        display: flex;
        justify-content: space-between;
        padding: 4px;
      }
    }
  }
}
.overlay-abso{
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  background: -o-linear-gradient(305deg, #584CF5 0%, #5587FA 100%);
  background: linear-gradient(145deg, #584CF5 0%, #5587FA 100%);
  opacity: 1;
  -webkit-transition: all .2s ease-in-out;
  -o-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
  z-index: -1;
  border-radius: 8px;
  overflow: hidden;
  &:before{
    content: "";
    background-color: white;
    height: auto;
    width: 6em;
    display: block;
    position: absolute;
    top: -1px;
    bottom: -1px;
    left: -6.3em;
    -webkit-transform: skewX(-35deg) translateX(0);
    -ms-transform: skewX(-35deg) translateX(0);
    transform: skewX(-35deg) translateX(0);
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
    -webkit-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
    border: 0;
    opacity: 0.08;
  }
  &:after{
    content: "";
    position: absolute;
    bottom: 0;
    right: -40px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0 68px 54px;
    border-color: transparent transparent #fff transparent;
    opacity: 0.08;
    -webkit-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    z-index: -1;
    background-color: white;
    -webkit-transform: skewX(-35deg) translateX(0);
    -ms-transform: skewX(-35deg) translateX(0);
    transform: skewX(-35deg) translateX(0);

  }
}
