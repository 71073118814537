@import '../../assets/scss/variables';
@import "../../assets/fonts/fonts";

.department-filters {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 60px;
  background-color: rgba(0,0,0,0.5);
  padding: 12px;
  z-index: 999;
  text-align: left;
  overflow: auto;
  .filters-card {
    max-width: 360px;
    margin: 0 auto;
    height: auto;
    padding-bottom: 20px;
    overflow-y: auto;
    border-radius: 5px;
    background-color: $NAV-BAR-BACKGROUND-COLOR;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
    margin-bottom: 150px;
    .search-wrapper{
      margin-top: 10px;
    }
    &-header {
      padding: 12px 20px 12px;
      font-size: $FONT-SIZE-SM-TITLE;
      font-weight: bold;
      color: $PRIMARY-DARK-GRAY-COLOR;
      border-bottom: 1px $PRIMARY-LIGHTER-GRAY-COLOR solid;
    }
    &-search {
      padding: 0 12px;
    }
    &-list {
      .MuiExpansionPanelDetails-root {
        flex-direction: column;
        padding: 0 24px 10px 52px;
      }
      .MuiExpansionPanel-root {
        box-shadow: none;
        background-color: $NAV-BAR-BACKGROUND-COLOR;
        border-bottom: 1px $PRIMARY-LIGHTER-GRAY-COLOR solid;
        &:before {
          display: none;
        }
        &.Mui-expanded {
          margin: 0;
        }
      }
      .MuiExpansionPanelSummary-content {
        margin: 0;
      }
      .MuiExpansionPanelSummary-root.Mui-expanded {
        min-height: 0;
      }
      .MuiIconButton-root {
        padding: 8px;
      }
      .MuiSvgIcon-colorPrimary, .MuiSvgIcon-root, .MuiCheckbox-colorSecondary, MuiSvgIcon-colorPrimary {
        color: $PRIMARY-GREEN-COLOR;
      }
      .MuiTypography-root {
        font-size: $FONT-SIZE-LG-TEXT;
        font-weight: 700;
        color: $PRIMARY-DARK-GRAY-COLOR;
      }
      .check-box {
        position: relative;
        height: 20px;
        width: 20px;
        border-radius: 5px;
        background-color: $CHECKBOX-BACKGROUND-COLOR;
        &-checked {
          background: url("../../assets/images/check_icon.svg") no-repeat center center;
          background-color: $PRIMARY-GREEN-COLOR;
          box-shadow: 0px 2px 5px rgba($PRIMARY-GREEN-COLOR, 0.6);
          background-size: 10px;
        }
        &-indeterminate {
          &:after {
            content: '';
            display: block;
            width: 10px;
            height: 2px;
            position: absolute;
            left: 5px;
            top: 9px;
            background-color: $PRIMARY-GREEN-COLOR;
            border-radius: 1px;
          }
        }
      }
    }
  }
  .children-panel{
    padding-left: 24px;
  }
}
.filter-btn-wrapper{
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 16px 20px;
  background: $PRIMARY-WHITE-COLOR;
  max-width: 360px;
  left: 0;
  right: 0;
  margin: auto;
  .btn-primary{
    height: 36px;
  }
}
