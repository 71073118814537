@import "../../assets/scss/variables";
@import "../../assets/fonts/fonts";

.mayor-program{
  .container{
    max-width: 991px;
    width: 100%;
    height: 100%;
    padding: 76px 0 76px 0;
    text-align: left;
  }
  .search-container{
    padding: 0 12px;
  }
  .list-content{
    li{
      border-bottom: 1px solid $INPUT-BORDER-COLOR;
      &:first-child{
        border-top: 1px solid $INPUT-BORDER-COLOR;
      }
      .item-link{
        font-weight: 600;
        font-size: $FONT-SIZE-LG-TEXT;
        color: $PRIMARY-DARK-GRAY-COLOR;
        padding: 20px 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .arrow-right{
        width: 16px;
        height: 23px;
      }
    }
    .counter-arrow{
      display: flex;
      align-items: center;
      color: $PRIMARY-DARK-GRAY-COLOR;
      img{
        margin-left: 10px;
        font-size: $FONT-SIZE-SM-TITLE;
        font-family: $SECONDARY-FONT-FAMILY;
      }
    }
  }
  .product-list-wrapper{
    h2{
      color: $PRIMARY-DARK-GRAY-COLOR;
      padding: 20px 20px 0 20px;
      font-size: $FONT-SIZE-LG-TITLE;
    }
    h3{
      color: $PRIMARY-DARK-GRAY-COLOR;
      text-align: center;
      margin-top: 15px;
    }
  }
  .product-list-wrapper{
    box-shadow: none;
    background: transparent;
  }
  .product-list{
    li{
      background: $PRIMARY-WHITE-COLOR;
      &:nth-child(odd) {
        background: $PRIMARY-WHITE-COLOR;
      }
      &.list-item{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0;
        margin-bottom: 25px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
        border-radius: 10px;
        overflow: hidden;
        .name{
          font-size: $FONT-SIZE-SM-TITLE;
          font-family: $SECONDARY-FONT-FAMILY;
          width: 100%;
          padding: 4px 16px;
          background: $PRIMARY-GREEN-COLOR;
          max-width: 100%;
          color: $PRIMARY-WHITE-COLOR;
        }
        .statuses{
          display: flex;
          width: 100%;
          ul{
            width: 100%;
            li{
              padding: 4px 20px;
              font-size: $FONT-SIZE-LG-TEXT;
              display: flex;justify-content: space-between;
              align-items: center;
              color: $PRIMARY-DARK-GRAY-COLOR;
              font-weight: bold;
              &:nth-child(odd) {
                background: #EEEEEE;
              }
            }
          }
        }
      }
    }
    .reseption-date{
      width: 100%;
      text-align: center;
      font-weight: 600;
      color: $PRIMARY-WHITE-COLOR;
      background: $PRIMARY-GREEN-COLOR;
      padding: 4px 0 12px 0;
      font-size: $FONT-SIZE-LG-TEXT;
    }
  }
  .statistic-list{
    box-shadow: 0 0 20px #00000047;
    border-radius: 10px;
    width: 100%;
    margin: 20px auto;
    overflow: hidden;
    li{
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 8px 16px;
      color: $PRIMARY-DARK-GRAY-COLOR;
      font-weight: 600;
      font-size: $FONT-SIZE-LG-TEXT;
      &:nth-child(odd) {
        background: #EEEEEE;
      }
      .sum{
        font-size: $FONT-SIZE-SM-TITLE;
        font-family: $SECONDARY-FONT-FAMILY;
      }
    }
  }

}

