@import "../../../assets/scss/variables";
@import "../../../assets/fonts/fonts";

.category-status-line{
  width: 100%;
  height: 58px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  font-family: $SECONDARY-FONT-FAMILY;
  color: #515151;
  font-weight: 600;
  border-top: 1px solid #D8D8D8;
  border-bottom: 1px solid #D8D8D8;
  margin-bottom: -1px;
  cursor: pointer;
  a{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
    color: $PRIMARY-DARK-GRAY-COLOR;
    padding: 12px;
    text-align: left;
    font-size: $FONT-SIZE-LG-TEXT;
    span{
      display: flex;
      align-items: center;
    }
    .title{
      max-width: 240px;
    }
  }
}
