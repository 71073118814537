@import "../../../assets/scss/variables";
@import "../../../assets/fonts/fonts";

.districts-filters{

  .card-details{
    .card-header{
      color: $PRIMARY-DARK-GRAY-COLOR;
    }
    .details-list{
      margin-top: 13px;
      li{
        &:nth-child(even){
          background: #EEEEEE;
        }
        &:nth-child(odd){
          background: #ffffff;
        }
      }
      .count {
        .title{
          font-size: 12px;
          font-family: $SECONDARY-FONT-FAMILY;
          font-weight: 600;
        }
      }
    }
  }
  .no-data{
    color: $PRIMARY-DARK-GRAY-COLOR;
    font-size: $FONT-SIZE-MD-TEXT;
    text-align: center;
    padding-bottom: 12px;
  }



}
