@import "../../assets/scss/variables";
@import "../../assets/fonts/fonts";

.header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  height: 60px;
  background: #ffffff;
  box-shadow: 0 0 1px rgba($PRIMARY-DARK-GRAY-COLOR, 0.5);
  z-index: 999;
  //background: rgba(87,96,247,1);
  //background: -moz-linear-gradient(left, rgba(87,96,247,1) 0%, rgba(85,125,249,1) 100%);
  //background: -webkit-gradient(left top, right top, color-stop(0%, rgba(87,96,247,1)), color-stop(100%, rgba(85,125,249,1)));
  //background: -webkit-linear-gradient(left, rgba(87,96,247,1) 0%, rgba(85,125,249,1) 100%);
  //background: -o-linear-gradient(left, rgba(87,96,247,1) 0%, rgba(85,125,249,1) 100%);
  //background: -ms-linear-gradient(left, rgba(87,96,247,1) 0%, rgba(85,125,249,1) 100%);
  //background: linear-gradient(to right, rgba(87,96,247,1) 0%, rgba(85,125,249,1) 100%);
  //filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#5760f7', endColorstr='#557df9', GradientType=1 );
  &.isAccident{
    h4{
      color: $PRIMARY-RED-COLOR;
      .title{
        color: $PRIMARY-RED-COLOR;
      }
    }
    .titles-list{
      .title-item{
        color: $PRIMARY-RED-COLOR;
        &.classifiers{
          color: $PRIMARY-RED-COLOR;
        }
      }
    }
    //background: -moz-linear-gradient(left, $PRIMARY-RED-COLOR 0%, #FF2E43 100%);
    //background: -webkit-linear-gradient(left, $PRIMARY-RED-COLOR 0%,#FF2E43 100%);
    //background: linear-gradient(to right, $PRIMARY-RED-COLOR 0%,#FF2E43 100%);
  }
  //filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#009f55', endColorstr='#40bc01',GradientType=1 );
  .header-logo{
    width: 24px;
    height: 24px;
    display: block;
    background: url("../../assets/images/logo-white.svg") no-repeat center;
    background-size: contain;
  }
  h4{
    color: $PRIMARY-WHITE-COLOR;
    display: inline;
    max-width: 100%;
    font-family: $SECONDARY-FONT-FAMILY;
    line-height: 16px;
    @media (max-width: 480px) {
      max-width: 280px;
    }
    .title{
      font-size: 14px;
      font-family: $SECONDARY-FONT-FAMILY;
      display: block;
      color: $PRIMARY-DARK-GRAY-COLOR;
    }
  }
  .header-nav{
    ul{
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .settings-btn{
      width: 24px;
      height: 24px;
      display: block;
      cursor: pointer;
    }
    .settings-btn{
      background: url("../../assets/images/filters.svg") no-repeat center;
      background-size: contain;
      margin-right: 19px;
    }
    .profile{
      //background: #000000;
      //box-sizing: border-box;
      //border: 1px solid $PRIMARY-WHITE-COLOR;
      //border-radius: 24px;
      //background-size: contain;
    }
  }
  .titles-list{
    .title-item{
      color: $PRIMARY-DARK-GRAY-COLOR;
      &:not(:first-child){
        &:before{
          content: ', ';
        }
      }
      &.classifiers{
        color: $PRIMARY-DARK-GRAY-COLOR;
        &:before{
          content: '';
        }
      }
    }
  }
  .title-item{
    font-size: 10px;
    &.classifiers{
      display: block;
    }
  }
  .back-arrow-wrapper{
    height: 36px;
    padding: 0;
  }
  .back-arrow{
    color: $PRIMARY-DARK-GRAY-COLOR;
    font-size: $FONT-SIZE-BIG-TITLE;
  }
}
