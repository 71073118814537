@import "../../../assets/scss/variables";
@import "../../../assets/fonts/fonts";

.economic-department{
  .container{
    max-width: 991px;
    width: 100%;
    height: 100%;
    padding: 76px 0 76px 0;
    text-align: left;
  }
  .search-container{
    padding: 0 12px;
  }
}
.list-content{
  li{
    border-bottom: 1px solid $INPUT-BORDER-COLOR;
    &:first-child{
      border-top: 1px solid $INPUT-BORDER-COLOR;
    }
    .item-link{
      font-weight: 600;
      font-size: $FONT-SIZE-LG-TEXT;
      color: $PRIMARY-DARK-GRAY-COLOR;
      padding: 24px 12px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .arrow-right{
      width: 16px;
      height: 23px;
    }
  }
}
