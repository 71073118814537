@import "../../assets/scss/variables";
@import "../../assets/fonts/fonts";

.profile-page{
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
  font-family: $SECONDARY-FONT-FAMILY;

  .content{
    padding: 68px 0 56px 0;
  }
  .user-avatar{
    width: 78px;
    height: 78px;
    border-radius: 50%;
    margin: 30px auto 0 auto;
    border: 2px solid $PRIMARY-WHITE-COLOR;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  }
  .user-info{
    padding: 16px 0 12px 0;
    text-align: left;
    display: flex;
    justify-content: space-between;
    span{
      display: block;
    }
    .user-name{
      font-size: $FONT-SIZE-SM-TITLE;
      color: $PRIMARY-DARK-GRAY-COLOR;
      font-weight: 600;
    }
    .user-email{
      font-size: $FONT-SIZE-SM-TITLE;
      font-weight: 600;
      color: $TEXT-LIGHT-GRAY-COLOR;
    }
  }
  .profile-list{
    li{
      height: 52px;
      border-bottom: 1px solid $INPUT-BORDER-COLOR;
      display: flex;
      align-items: center;
      padding: 0 10px;
      .link{
        font-size: $FONT-SIZE-SM-TITLE;
        color: $PRIMARY-DARK-GRAY-COLOR;
        font-weight: 600;
        padding: 0;
        background: transparent;
        box-shadow: none;
        text-transform: none;
        justify-content: space-between;
        width: 100%;
        display: flex;
        &.logout{
          color: $PRIMARY-RED-COLOR;
        }
      }

      &:first-child{
        border-top: 1px solid $INPUT-BORDER-COLOR;
      }
    }
  }
  .app-version{
    color: $PRIMARY-LIGHT-GRAY-COLOR;
    font-size: $FONT-SIZE-LG-TEXT;
  }
}
.change-password-view{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 10px;
  .input-block{
    width: 100%;
    position: relative;
    .showPassword{
      position: absolute;
      right: 10px;
      top: 0;
      bottom: 0;
      height: 40px;
      display: flex;
      align-items: center;
      z-index: 22;
      cursor: pointer;
    }
  }
  label{
    font-size: $FONT-SIZE-SM-TITLE;
    margin-bottom: 10px;
  }
  button{
    background: $PRIMARY-GREEN-COLOR;
    color: $PRIMARY-WHITE-COLOR;
    display: flex;
    height: 40px;
    width: 100%;
    align-items: center;
    text-align: center;
    justify-content: center;
    border-radius: 5px;
    transition: 0.33s;
    &:hover{
      border: 2px solid $PRIMARY-GREEN-COLOR;
      color: $PRIMARY-GREEN-COLOR;
      background: transparent;
    }
  }
  .input{
    border: none;
    border-bottom: 2px solid $PRIMARY-LIGHT-GRAY-COLOR;
    width: 100%;
    height: 40px;
    color: #515151;
    border-radius: 0;
    margin-bottom: 30px;
    padding-right: 40px;
    &:focus{
      border-bottom: 2px solid $PRIMARY-GREEN-COLOR;
    }
  }
  .error-text{
    color: $PRIMARY-RED-COLOR;
    font-size: $FONT-SIZE-LG-TEXT;
    margin-bottom: 15px;
    margin-top: -15px;
  }
  .success-text{
    color: $PRIMARY-GREEN-COLOR;
    font-size: $FONT-SIZE-LG-TEXT;
    margin-bottom: 15px;
    margin-top: -15px;
  }
}
