@import '../../assets/scss/variables';
@import "../../assets/fonts/fonts";

.card-details {
  background: #F8F8F8;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
  margin: 10px 0;
  border-radius: 5px;
  text-align: left;
  &.events{
    li{
      padding-right: 20px!important;
    }
  }
  .success-alert{
    padding: 4px 12px;
    text-align: center;
    background: $PRIMARY-GREEN-COLOR;
    color: #fff;
    font-size: $FONT-SIZE-MD-TEXT;
    font-family: $SECONDARY-FONT-FAMILY;
    transition: 0.33s;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 99;
    &.exist{
      background: $PRIMARY-RED-COLOR;
    }
  }
  .card-header{
    padding: 16px 16px 0 16px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    .card-title{
      display: flex;
      flex-direction: column;
      .opened-time{
        font-size: $FONT-SIZE-MD-TEXT;
        font-family: $SECONDARY-FONT-FAMILY;
        font-weight: bold;
        color: $PRIMARY-RED-COLOR;
      }
    }
  }
  h3 {
    color: $PRIMARY-DARK-GRAY-COLOR;
    margin-top: 10px;
    line-height: 19px;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
    &.alert-title{
      width: fit-content;
      padding: 4px 12px 4px 12px;
      font-size: $FONT-SIZE-LG-TEXT;
      background: $PRIMARY-RED-COLOR;
      display: inline-flex;
      color: $PRIMARY-WHITE-COLOR;
      border-radius: 20px;
    }
  }
  .details-list{
    margin-top: 15px;
    &.filteredList{
      margin-top: 0;
    }
    li{
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      color: $PRIMARY-DARK-GRAY-COLOR;
      font-size: $FONT-SIZE-MD-TEXT;
      font-family: $SECONDARY-FONT-FAMILY;
      padding: 8px 36px 4px 20px;
      font-weight: 600;
      position: relative;
      &:nth-child(odd){
        background: #EEEEEE;
      }
      .disabled{
        position: absolute;
        width: 100%;
        background: transparent;
        height: 100%;
        z-index: 2;
        left: 0;
        opacity: 0.4;
        top: 0;
        cursor: no-drop;
      }
      span{
        font-size: $FONT-SIZE-SM-TITLE;
        text-align: right;
        &.alert-color{
          color: $PRIMARY-RED-COLOR;
        }
        &.category-title{
          max-width: 190px;
        }
      }
      .material-icons{
        &.arrow-right{
          position: absolute;
          right: 5px;
          color: $PRIMARY-DARK-GRAY-COLOR;
          font-size: $FONT-SIZE-LG-TITLE;
        }
      }
      .adress{
        max-width: 200px;
        display: flex;
        flex-direction: column;
        text-align: right;
      }
    }
  }
}

