@import '../../assets/scss/variables';
@import "../../assets/fonts/fonts";

.open-time-picker{
  position: relative;
}
.time-picker-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 44px;
  font-weight: 600;
  font-size: $FONT-SIZE-LG-TEXT;
  color: $PRIMARY-DARK-GRAY-COLOR;
  .date-block{
    .date-text{
      margin-right: 5px;
    }
  }
}
.time-picker-wrapper{
  width: calc(100% - 20px);
  max-width: 375px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
  border-radius: 5px;
  position: absolute;
  left: 0;
  right: 10px;
  background: #fff;
  transition: 0.33s;
  opacity: 0;
  z-index: -1;
  padding-bottom: 28px;
  &.show{
    opacity: 1;
    top: 5px;
    z-index: 99;
  }
  .MuiPickersCalendar-week{
    align-items: flex-start;
    justify-content: space-between;
    margin: 1px 0;
  }
  .MuiPickersCalendar-transitionContainer{
    margin-top: 1px;
  }
  .MuiPickersBasePicker-pickerView{
    max-width: 281px;
    min-width: 280px;
    overflow: hidden;
  }
  .MuiPickersBasePicker-container,
  .MuiPickersStaticWrapper-staticWrapperRoot{
    min-width: 280px;
  }
  .MuiPickersCalendarHeader-daysHeader{
    border-top: 1px solid #DFE4E8;
    border-bottom: 1px solid #DFE4E8;
    max-height: 32px;
    height: 32px;
  }
  .MuiFormGroup-root{
    padding: 12px;
    padding-bottom: 0;
    margin-bottom: -10px;
  }
  .MuiTypography-body1{
    font-size: $FONT-SIZE-LG-TEXT;
    color: $PRIMARY-DARK-GRAY-COLOR;
    font-weight: 600;
    margin-left: 9px;
  }
  .MuiRadio-root{
    padding: 0;
  }
  .MuiRadio-colorSecondary{
    color: #EFEFEF;
    &.Mui-checked{
      color: $PRIMARY-GREEN-COLOR;
      background: #EFEFEF;
    }
  }
  .MuiFormControlLabel-root{
    padding-left: 12px;
    margin-bottom: 18px;
  }
  .MuiIconButton-label{
    background-color: #EFEFEF;
    border-radius: 50%;
  }
  .picker-wrapper{
    position: relative;
    margin: 0 auto;
  }
  .arrow-up{
    position: absolute;
    right: 10px;
    top: 10px;
    color: $PRIMARY-GREEN-COLOR;
    font-size: 26px;
  }
}
.MuiIcon-root{
  &.arrow-down{
    color: $PRIMARY-GREEN-COLOR;
    font-size: 26px;
  }
}

