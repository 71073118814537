@import '../../assets/scss/variables';
@import "../../assets/fonts/fonts";

.picker-wrapper{
  .MuiPickersCalendarHeader-switchHeader{
    justify-content: flex-end;
    position: relative;
    margin-bottom: 0;
  }
  .MuiPickersCalendarHeader-transitionContainer{
    position: absolute;
    left: 0;
  }
  .MuiPickersSlideTransition-transitionContainer{
    & >{
      p{
        left: 36px;
        right: inherit;
        font-weight: bold;
      }
    }
  }
  .MuiSvgIcon-root{
    color: $PRIMARY-GREEN-COLOR;
  }
  .MuiPickersDay-current{
    color: $PRIMARY-GREEN-COLOR;
  }
  .MuiPickersDay-daySelected{
    background-color: transparent;
    font-weight: 600;
    color: $PRIMARY-GREEN-COLOR;
    border-radius: 5px;
    &:hover{
      background-color: rgba($PRIMARY-GREEN-COLOR,0.8);
      color: #FFFFFF;
    }
  }
  .MuiPickersCalendarHeader-iconButton{
    .MuiIconButton-label{
      background: transparent;
    }
  }
  .MuiIconButton-root{
    .MuiIconButton-label{
      background: transparent;
    }
  }
  .overlay{
    position: absolute;
    z-index: 99;
    background: rgba(255, 255, 255, 0.7);
    width: 100%;
    height: 100%;
  }
}

