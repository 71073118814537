@import '../../assets/scss/variables';
@import "../../assets/fonts/fonts";

.requests-summary {
  .card-details {
    background: #F8F8F8;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
    margin: 10px 0 20px 0;
    border-radius: 5px;
    text-align: left;
    position: relative;
    .accident-title{
      color: $PRIMARY-RED-COLOR;
      font-size: 14px;
      font-weight: 600;
      margin-left: 15px;
      margin-top: 5px;
      margin-bottom: -5px;
    }
    .card-header{
      padding: 16px 16px 0 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .title-name{
        display: flex;
        align-items: center;
        justify-content: center;
        color: $PRIMARY-DARK-GRAY-COLOR;
      }
      .card-title{
        font-size: $FONT-SIZE-SM-TITLE;
        font-family: $SECONDARY-FONT-FAMILY;
        font-weight: bold;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
    }
    .card-footer {
      padding: 20px 16px;
      text-align: center;
      color: $PRIMARY-LIGHT-GRAY-COLOR;
      font-size: $FONT-SIZE-SM-TEXT;
      font-family: $SECONDARY-FONT-FAMILY;
      font-weight: bold;
    }
    .card-status-line {
      padding: 16px 16px 4px;
      overflow: hidden;
      margin-bottom: 10px;
    }
    h3 {
      color: $PRIMARY-DARK-GRAY-COLOR;
      margin-top: 10px;
      line-height: 19px;
      align-items: center;
      justify-content: center;
      margin-bottom: 15px;
      &.alert-title{
        width: fit-content;
        padding: 8px 12px 8px 12px;
        background: $PRIMARY-RED-COLOR;
        display: inline-flex;
        color: $PRIMARY-WHITE-COLOR;
        border-radius: 20px;
      }
    }
    .details-list{
      margin-top: 0;
      li{
        color: $PRIMARY-DARK-GRAY-COLOR;
        font-size: $FONT-SIZE-MD-TEXT;
        font-family: $SECONDARY-FONT-FAMILY;
        padding: 8px 24px 8px 20px;
        font-weight: 600;
        position: relative;
        .overlay{
          opacity: 0.8;
          position: absolute;
          z-index: 22;
          cursor: no-drop;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          background: #F8F8F8;
        }
        &:nth-child(odd){
          background: #EEEEEE;
        }
        .list-item{
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        span{
          font-size: $FONT-SIZE-SM-TITLE;
        }
      }
    }
    .count{
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: $PRIMARY-DARK-GRAY-COLOR;
      font-size: $FONT-SIZE-MD-TEXT;
      font-family: $SECONDARY-FONT-FAMILY;
      padding: 8px 24px 8px 20px;
      font-weight: 600;
      &.general-count{
        //background: #EEEEEE;
        margin-top: 10px;
        position: relative;
        .sum-arrow{
          position: relative;
        }
        .arrow-right{
          position: absolute;
          right: -21px;
          display: flex;
          align-items: center;
          top: 0;
          height: 24px;
          font-size: 21px;
        }
        .overlay{
          position: absolute;
          z-index: 99;
          background: #F8F8F8;
          opacity: 0.8;
          width: 100%;
          height: 100%;
          left: 0;
          cursor: no-drop;
        }
      }
      .summary-text{
        font-size: $FONT-SIZE-SM-TITLE;
        font-family: $SECONDARY-FONT-FAMILY;
      }
    }
    .card-date{
      text-align: center;
      margin-bottom: -10px;
      padding-top: 12px;
      font-size: $FONT-SIZE-LG-TEXT;
      font-weight: 600;
      color: $PRIMARY-DARK-GRAY-COLOR;
    }
  }
}
.overlay{
  position: absolute;
  background: transparent;
  height: 100%;
  z-index: 22;
  width: 100%;
}
